@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$navigator-item-bgcolor: keys.$default-bgcolor; 
$navigator-item-color: white;
$navigator-item-hover-color: rgba(84,209,252, 1);

@mixin header-navigator {
	flex: 0 0 60%;
	display: flex;
	flex-flow: row wrap;
	background-color: $navigator-item-bgcolor;
	.navigator-item {
		flex: 0 0 100%;
		display: flex;
		justify-content: space-around;
		overflow: hidden;
		a {
			flex: 0 0 auto;
			font-size: 1.3rem;
			@include enable-font-nunito-bold;
			color: $navigator-item-color;
			padding: 2rem 1rem;
			text-decoration: none;
			transition: 0.3s color ease-in-out,
						0.1s transform ease-in-out;
			&:hover {
				cursor: pointer;
				color: $navigator-item-hover-color;
				transform: scale(1.2);
			}
		}
	}
}

.header-navigator,
.header-navigator-sandwich {
	@include header-navigator;		
}

.header-navigator {
	&.hide {
		display: flex;
	}
}
.header-navigator-sandwich {
	flex: 0 0 10%;
	.navigator-item {
		a {
			display: none;
		}
	}
}