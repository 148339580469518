
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$navigator-item-bgcolor-hover: rgba(1,121,234, 1);
$navigator-item-bgcolor: rgba(13,56,151, 0.9);
$navigator-item-svg-width: 50px;

@mixin service-navigator {
	flex: 0 0 100%;
	@include enable-flex-justify;
	.navigator-item {
		flex: 0 0 100%;
		@include enable-flex;
		a {
			transition: 0.3s background-color ease-in-out,
						0.5s padding ease-in-out;
			flex: 0 0 20%;
			text-decoration: none;
			color: white;
			text-align: center;
			background-color: $navigator-item-bgcolor;
			padding: 2rem;
			@include enable-font-nunito-semibold;
			font-size: 1.1rem;
			&:hover {
				background-color: $navigator-item-bgcolor-hover;
			}
			&.active {
				background-color: $navigator-item-bgcolor-hover;
			}
			svg {
				display: block;
				margin: 0.7rem auto;
				width: $navigator-item-svg-width;
				height: auto;
				color: white;
			}
		}
	}
}

@mixin service-tab {
	transition: 0.5s padding ease-in-out;
	flex: 0 0 100%;
	background-color: $navigator-item-bgcolor-hover;
	padding: 2rem;
	@include enable-flex;
	align-items: center;
	.tab-title {
		transition: 0.5s padding-left ease-in-out,
					0.5s padding-right ease-in-out,
					0.5s margin-top ease-in-out;
		flex: 0 0 30%;
		text-align: center;
		padding-left: 4rem;
		padding-right: 4rem;
		h2 {
			transition: 0.5s margin-bottom ease-in-out;
			color: white;
			@include enable-font-nunito;
			font-size: 1.3rem;
		}
	}
	.tab-content {
		transition: 0.5s padding-left ease-in-out,
					0.5s padding-right ease-in-out,
					0.5s padding-top ease-in-out;
		flex: 0 0 70%;
		padding: 2rem;
		p,
		ul li {
			color: white;
			@include enable-font-nunito-semibold;
			font-size: 1rem;
		}
	}
}


.service-layer {
	flex: 0 0 100%;
	@include enable-flex-justify;
	.service-navigator {
		@include service-navigator;
	}
	.service-tab {
		@include service-tab;
	}
}