
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$about-icon-width: 45px;
$about-subtitle-color: keys.$default-bgcolor;
$default-box-height: 400px;

@mixin about-box {
	@include enable-flex;
	.box-image {
		transition: 0.5s height ease-in-out;
		flex: 0 0 50%;
		height: 100%;
		background-size: 95%;
		background-attachment: initial;
		background-position: top;
		background-repeat: no-repeat;
	}
	.box-content {
		transition: 0.5s height ease-in-out;
		flex: 0 0 50%;
		height: 100%;
		padding: 2rem;
		svg {
			float: left;
			width: $about-icon-width;
			height: auto;
			color: $about-subtitle-color;
		}
		h2, h3, p {
			@include enable-font-nunito;
		}
		h2 {
			margin-top: 0;
			font-size: 1.6rem;
		}
		h3 {
			float: left;
			color: $about-subtitle-color;
			font-size: 1.3rem;
		}
		p {
			clear: both;
			font-size: 1.2rem;
		}
	}
}

@mixin about-mission {
	flex: 0 0 100%;
	height: $default-box-height;
	@include about-box;
	
}

@mixin about-vision {
	flex: 0 0 100%;
	height: $default-box-height;
	@include about-box;
	.box-image {
		background-position: bottom;
	}
}

.about-layer {
	flex: 0 0 100%;
	height: auto;
	@include enable-flex;
	.about-mission {
		@include about-mission;
	}
	.about-vision {
		@include about-vision;	
	}
}