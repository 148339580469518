
@use 'src/media/styles/variables.scss' as keys;
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/nexa-bold');

$main-button-border-color: rgba(198,201,202,1);
$main-button-color: keys.$default-bgcolor;

@mixin enable-flex {
	display: flex;
	flex-flow: row wrap;
}
@mixin enable-flex-justify {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}

@mixin enable-font-nunito {
	font-family: "Nunito", sans-serif;
}

@mixin enable-font-nunito-bold {
	font-family: "Nunito", sans-serif;
	font-weight: bold;
}

@mixin enable-font-nunito-semibold {
	font-family: "Nunito", sans-serif;
	font-weight: 600;
}

@mixin main-button {
	display: block;
	a {
		display: inline-block;
		margin-top: 1.6rem;
		padding: 0.6rem 2rem;
		background-color: white;
		@include enable-font-nunito-bold;
		font-size: 1.3rem;
		color: $main-button-color;
		text-decoration: none;
		border: 1px solid $main-button-border-color;
		box-shadow: 0 3px 3px $main-button-border-color;
		&:hover {
			cursor: pointer;
		}
	}
}

@mixin contact-form-input-error($font-color) {
	.error {
		color: $font-color;
		font-weight: bold;
		svg {
			padding-right: 0.3rem;
		} 
	}
}

@mixin contact-form-input($border-color, $font-color) {
	textarea,
	input {
		outline: none;
		padding: 0.5rem;
		border-radius: 0.6rem;
		width: 100%;
		background: transparent;
		color: $font-color;
		border: solid 1px $border-color;
		@include enable-font-nunito-semibold;
		font-size: 1rem;
		margin-bottom: 0.3rem;
	}
}