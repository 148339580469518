
@import 'src/media/styles/general.scss';

$form-h2-color: rgba(61,59,60,1);
$form-h3-color: rgba(17,47,115,1);
$form-h3-svg-color: $form-h3-color;
$form-button-bgcolor: rgba(1,121,234,1);
$form-button-border-color: $form-h3-color;
$form-textarea-border-color: $form-button-bgcolor;
$form-textarea-color: rgba(0,0,0,1);

@mixin claim-form-error {
	.error {
		display: block;
		margin-top: 0.3rem;
		color: red;
	}
}

@mixin claim-form-radio {
	label {
		&:hover {
			cursor: pointer;
		}
		input[type="radio"] {
			&:hover {
				cursor: pointer;
			}
		}
		span {
			@include enable-font-nunito;
			color: black;
			font-size: 1.2rem;
			padding-left: 0.5rem;
		}
	}
}

@mixin claim-form-input {
	textarea {
		outline: none;
		padding: 1rem;
		border-radius: 0.6rem;
		width: 100%;
		background: transparent;
		color: $form-textarea-color;
		border: solid 1px $form-textarea-border-color;
		@include enable-font-nunito-semibold;
		font-size: 1.1rem;
		margin-bottom: 0.3rem;
	}
}

@mixin claim-form {
	transition: 0.5s padding ease-in-out;
	flex: 0 0 50%;
	padding: 2.5rem;
	@include enable-flex-justify;

	h3,
	.form-item,
	.form-item-radio,
	.claim-button {
		transition: 0.7s flex ease-in-out;
		flex: 0 0 70%;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem
	}

	h3 {
		@include enable-font-nunito;
		color: $form-h3-color;
		font-size: 1.2rem;
	}

	.form-item,
	.form-item-radio {
		@include claim-form-error;
	}		

	.form-item-radio {
		@include claim-form-radio;
	}
	.form-item {
		@include claim-form-input;
	}
	.claim-button {
		@include main-button;
		text-align: center;
		a {
			background-color: $form-button-bgcolor;
			color: white;
			border: solid 1px $form-button-border-color;
			border-radius: 0.7rem;
		}
	}
}

@mixin claim-image {
	transition: 0.5s background-size ease-in-out;
	flex: 0 0 50%;
	height: 633px;
	background-size: 55%;
	background-repeat: no-repeat;
	background-position: center;
}

@mixin content {
	flex: 0 0 100%;
	@include enable-flex-justify;
	h2 {
		flex: 0 0 100%;
		@include enable-font-nunito;
		color: $form-h2-color;
		font-size: 1.6rem;
		padding-left: 1.5rem;
		margin-top: 2rem;
		clear: both;
		label, svg {
			float: left;
		}
		svg {
			width: 45px;
			color: $form-h3-svg-color;
			margin-top: 0.5rem;
		}
		label {
			padding-left: 0.3rem;
		}
	}
	.claim-image {
		@include claim-image;
	}
	
	.claim-form {
		@include claim-form;
	}
}

.claim-layer {
	flex: 0 0 100%;
	@include enable-flex-justify;
	.content {
		@include content;
	}
}