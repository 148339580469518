
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$footer-copyright-bgcolor: rgba(3,55,114, 1);
$footer-contact-bgcolor: keys.$default-bgcolor;

@mixin footer-copyright {
	flex: 0 0 100%;
	text-align: center;
	background-color: $footer-copyright-bgcolor;
	color: white;
	padding: 1rem 0;
	span {
		label {
			font-size: 1.2rem;
			@include enable-font-nunito-bold;
		}
	}
}
@mixin footer-contact {
	color: white;
	flex: 0 0 100%;
	background-color: $footer-contact-bgcolor;
	@include enable-flex-justify;
	.footer-contact-social {
		flex: 0 0 50%;
		padding: 2rem 1rem;
		display: flex;
		@include enable-flex-justify;
		column-gap: 1rem;
		transition: 1s margin-top ease-in-out,
					1s padding-bottom ease-in-out,
					0.3s flex ease-in-out;
		label {
			flex: 0 0 auto;
			font-size: 1.7rem;
			@include enable-font-nunito-semibold;
		}
		span {
			flex: 0 0 auto;
			a {
				border-radius: 50%;
				padding: 1rem;
				margin-right: 0.5rem;
				font-size: 1.7rem;
				background-color: white;
				color: $footer-contact-bgcolor;
				transition: 1s margin-bottom ease-in-out;
				svg {
					transition: 0.2s transform ease-in-out;
				}
				&:hover {
					cursor: pointer;
					svg {
						transform: scale(1.5);
					}
				}
			}
		}
	}
	.footer-contact-labels {
		flex: 0 0 50%;
		padding: 2rem 1rem;
		article {
			p {
				font-size: 1.4rem;
				@include enable-font-nunito;
				transition: 0.5s margin ease-in-out;
			}
			h2 {
				font-size: 1.8rem;
				@include enable-font-nunito;
			}
		}
	}
}

.footer-layer {
	flex: 0 0 100%;
	@include enable-flex-justify;

	.footer-contact {
		@include footer-contact;
	}
	.footer-copyright {
		@include footer-copyright;
	}
}