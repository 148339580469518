
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$header-info-bgcolor: rgba(17,47,115, 1);
$header-info-color: white;
$header-logo-bgcolor: keys.$default-bgcolor;

@mixin header-info {
	padding-top: 1rem;
	padding-bottom: 1rem;
	flex: 0 0 100%;
	display: flex;
	background-color: $header-info-bgcolor;

	.info-email,
	.info-phone,
	.info-social {
		transition: 0.5s padding-left ease-in-out,
					0.5s padding-right ease-in-out;
		span {
			a {
				text-decoration: none;
				color: $header-info-color;
				font-size: 1.1rem;
				@include enable-font-nunito-bold;
				svg {
					transition: 0.1s transform ease-in-out;
				}
				&:hover {
					cursor: pointer;
					svg {
						transform: scale(1.5);
					}
				}
				label {
					padding-left: 0.5rem;
					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	.info-email,
	.info-phone {
		flex: 0 0 25%;
		padding-left: 1rem;
	}
	.info-social {
		flex: 0 0 50%;
		@include enable-flex;
		justify-content: flex-end;
		padding-right: 3rem;
		span {
			flex: 0 0 1;
			a {
				font-size: 1.2rem;
				margin: 0 0.7rem;
			}
		}
	}
}

@mixin header-logo {
	flex: 0 0 30%;
	background-color: $header-logo-bgcolor;
}

.header-layer {
	flex: 0 0 100%;
	display: flex;
	flex-flow: row wrap;
	height: auto;
	.header-info {
		@include header-info;
	}
	.header-logo {
		@include header-logo;	
	}
}