
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$rates-download-bg-color: keys.$default-bgcolor;
$rates-download-icon-width: 45px;
$default-layer-height: 450px;

@mixin rates-download {
	flex: 0 0 50%;
	height: auto;
	@include enable-flex-justify;
	background-color: $rates-download-bg-color;

	.download-content {
		// position: relative;
		// &::after {
		// 	content: "";
		// 	position: absolute;
		// 	left:0;
		// 	bottom: 0;
		// 	width: 0;
		// 	height: 0;
		// 	border-left: 45px solid red;
		// 	border-top: 45px solid transparent;
		// 	// border-bottom: 45px solid transparent;
		// }

		flex: 0 0 100%;
		text-align: center;
		padding: 2rem 4rem;
		transition: 0.5s padding ease-in-out;
		h3, p {
			@include enable-font-nunito;
			color: white;
		}
		p {
			margin-top: 1rem;
			display: inline-block;
			clear: both;
			font-size: 1.2rem;
			font-weight: 600;
		}
		span {
			display: inline-block;
			svg {
				margin-top: 0.5rem;
				width: $rates-download-icon-width;
				height: auto;
				color: white;
			}
			h3 {
				margin-top: 0;
				padding-left: 0.7rem;
				font-size: 1.6rem;
			}
			svg, h3 {
				float: left;
			}
		}
		

		.download-button {
			@include main-button;
		}
	}
}

.rates-layer {
	flex: 0 0 100%;
	height: $default-layer-height;
	@include enable-flex;
	justify-content: flex-end;
	align-items: flex-start;
	background-size: 90%;
	background-attachment: initial;
	background-position: left top;

	.rates-download {
		@include rates-download;
	}
}