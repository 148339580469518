
$claim-book-box-height: 350px;
$about-box-height: 400px;

@media only screen and (max-width: 1200px) {
	.main-layer {
		.header-layer {
			.header-info {
				justify-content: space-around;
				.info-email,
				.info-phone,
				.info-social {
					flex: 0 0 auto;
					padding-left: 0;
					padding-right: 0;
				}
			}
			.header-navigator {
				.navigator-item {
					a {
						padding-right: 0.5rem;
						padding-left: 0.5rem;
					}
				}
			}
		}
		.body-layer {
			.about-layer {
				.about-mission,
				.about-vision {
					.box-image {
						background-size: cover;
					}
				}
			}
			.service-layer {
				.service-tab {
					.tab-title {
						padding-right: 2rem;
						padding-left: 2rem;
					}
				}
			}
			.rates-layer {
				background-size: cover;
			}
		}
		.claim-layer {
			.content {
				.claim-image {
					background-size: 80%;
				}
			}
		}
	}
}

@media only screen and (max-width: 980px) {
	.main-layer {
		.header-layer {
			.header-logo {
				flex: 0 0 80%;
			}

			.header-navigator-sandwich,
			.header-navigator {
				.navigator-item a {
					padding: 1rem 0;
				}
			}
			.header-navigator-sandwich {
				flex: 0 0 20%;
				.navigator-item {
					justify-content: center;
					a {
						display: flex;
					}
				}
			}
			.header-navigator {
				flex: 0 0 100%;
				&.hide {
					display: none;
				}
				.navigator-item {
					flex-flow: row wrap;
					a {
						flex: 0 0 100%;
						text-align: center;
					}
				}
			}
		}
		.body-layer {
			.about-layer {
				.about-mission,
				.about-vision {
					height: auto;
					.box-image,
					.box-content {
						flex: 0 0 100%;
					}
					.box-image {
						height: $about-box-height;
					}
					.box-content {
						height: auto;
					}
				}

				.about-vision {
					.box-image {
						order: 1;
					}
					.box-content {
						order: 2;
					}
				}
			}

			.service-layer {
				.service-navigator {
					.navigator-item {
						a {
							padding: 2rem 1rem;
						}
					}
				}
				.service-tab {
					padding: 0;
					.tab-title,
					.tab-content {
						padding-right: 1rem;
						padding-left: 1rem;
					}
				}
			}
			.rates-layer {
				.rates-download {
					.download-content {
						padding: 2rem 1.5rem;
					}
				}
			}

			.claim-book-layer {
				.book-content,
				.book-image {
					flex: 0 0 100%;
					height: $claim-book-box-height;
				}
			}
			.contact-layer {
				.contact-form {
					flex: 0 0 100%;
					height: auto;
				}
				.contact-labels {
					flex: 0 0 100%;
				}
			}
		}
		.claim-layer {
			.content {
				.claim-image {
					background-size: cover;
				}
				.claim-form {
					padding: 1rem 0.5rem;
				}
			}
		}
		.footer-layer {
			.footer-contact {
				.footer-contact-social,
				.footer-contact-labels {
					flex: 0 0 100%;
				}

				.footer-contact-social {
					margin-top: 1rem;
					padding-bottom: 0;
				}
				.footer-contact-labels {
					article {
						p {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 640px) {
	.main-layer {
		.header-layer {
			.header-info {
				.info-email,
				.info-phone,
				.info-social {
					span a {
						label {
							display: none;
						}
					}
				}
			}
		}
		.body-layer {
			.service-layer {
				.service-navigator {
					.navigator-item {
						a {
							padding: 2rem 0;
							font-size: 1rem;
						}
					}
				}
				.service-tab {
					.tab-title,
					.tab-content {
						flex: 0 0 100%;
					}
					.tab-title {
						margin-top: 2.5rem;
						h2 {
							margin-bottom: 0.5rem;
						}
					}
					.tab-content {
						padding-top: 0.5rem;
					}
				}
			}
			.rates-layer {
				.rates-download {
					flex: 0 0 60%;
				}
			}
			.claim-book-layer {
				.book-content {
					.book-title {
						h2 {
							font-size: 1.4rem;
						}
					}
				}
			}
		}
		.claim-layer {
			.content {
				.claim-image {
					flex: 0 0 25%;
					background-position-x: 41%;
				}
				.claim-form {
					flex: 0 0 75%;
					
					h3,
					.form-item,
					.form-item-radio,
					.claim-button {
						flex: 0 0 85%;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 480px) {
	.main-layer {
		.body-layer {
			.service-layer {
				.service-navigator {
					.navigator-item {
						a {
							font-size: 0.9rem;
						}
					}
				}
			}
		}
		.footer-layer {
			.footer-contact {
				.footer-contact-social {
					label {
						margin-bottom: 1.5rem;
					}
				}
			}
		}
	}
}