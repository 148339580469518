
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$tracking-h3-color: keys.$default-bgcolor;
$tracking-input-color: keys.$default-bgcolor;
$tracking-input-border-color: rgba(84,209,252, 1);
$tracking-button-border-color: keys.$default-bgcolor;

@mixin tracking-input-error {
	@include contact-form-input-error(red);
	.error {
		display: block;
		font-weight: normal;
		clear: both;
	}
}

@mixin tracking-input {
	@include contact-form-input($tracking-input-border-color, $tracking-input-color);
}

@mixin tracking-select {
	div {
		border-color: $tracking-input-border-color;
		@include enable-font-nunito;
		font-size: 1rem;
		color: $tracking-input-color;
	}
}

@mixin tracking-radio-button {
	.form-item-radio {
		display: block;
		float: left;
		margin: 1rem 2rem 1rem 0;
		label {
			&:hover { cursor: pointer; }
			span {
				@include enable-font-nunito-semibold;
				font-size: 1rem;
				&:hover { cursor: pointer; }
			}
		}
	}
}

@mixin tracking-button {
	@include main-button;
	flex: 0 0 100%;
	text-align: center;
	a {
		margin-top: 0.5rem;
		color: white;
		border-color: $tracking-button-border-color;
		background-color: $tracking-button-border-color;
		border-radius: 1rem;
		font-size: 1.2rem;
	}
}

@mixin tracking-tab {
	padding: 1rem 2rem 3.5rem 2rem;
	flex: 0 0 100%;
	@include enable-flex;
	justify-content: space-between;

	h3 {
		flex: 0 0 100%;
		@include enable-font-nunito-semibold;
		color: $tracking-h3-color;
		font-size: 1.1rem;
	}

	.form-item {
		flex: 0 0 100%;
		@include tracking-input;
		@include tracking-select;
		@include tracking-input-error;
	}

	.form-radio-buttons {
		flex: 0 0 100%;
		@include tracking-radio-button;
		@include tracking-input-error;
	}

	.tracking-button {
		@include tracking-button;	
	}
}

.body-layer {
	.operations-layer {
		.tracking-tab {
			@include tracking-tab;
		}
	}
}