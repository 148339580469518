
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$contact-label-icon-width: 45px;
$contact-label-color: keys.$default-bgcolor;
$contact-form-bgcolor: $contact-label-color;

@mixin contact-form-input-error {
	.error {
		color: white;
		font-weight: bold;
		svg {
			padding-right: 0.3rem;
		} 
	}
}

@mixin contact-form-input {
	textarea,
	input {
		outline: none;
		padding: 0.5rem;
		border-radius: 0.6rem;
		width: 100%;
		background: transparent;
		color: white;
		border: solid 1px white;
		@include enable-font-nunito-semibold;
		font-size: 1rem;
		margin-bottom: 0.3rem;
	}
}

@mixin contact-form {
	transition: 1s height ease-in-out;
	flex: 0 0 50%;
	background-color: $contact-form-bgcolor;
	height: 100%;
	article {
		padding: 3rem 1.8rem 2rem 1.8rem;
		h2, p {
			@include enable-font-nunito;
			color: white;
		}
		h2 {
			font-size: 1.6rem;
		}
		p {
			font-size: 1.3rem;
		}

		form {
			@include enable-flex;
			justify-content: space-between;

			.form-item-name,
			.form-item-lastname,
			.form-item-phone,
			.form-item-email {
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				flex: 0 0 48%;
				@include contact-form-input;
				@include contact-form-input-error;
			}
			.form-item {
				margin-top: 0.5rem;
				margin-bottom: 0.5rem;
				flex: 0 0 100%;
				@include contact-form-input;
				@include contact-form-input-error;
			}

			.contact-button {
				@include main-button;
				margin: 0 auto;
				a {
					border-radius: 0.5rem;
				}
			}
		}
	}
}

@mixin contact-labels {
	flex: 0 0 50%;
	@include enable-flex;
	flex-flow: column nowrap;
	margin: 2rem 0;
	.label-item {
		flex: 0 0 auto;
		width: 80%;
		margin: 1.5rem auto;

		svg {
			float: left;
			width: $contact-label-icon-width;
			height: auto;
			color: $contact-label-color;
		}
		article {
			margin-left: 1rem;
			float: left;
			h3, p {
				@include enable-font-nunito;
			}
			h3 {
				font-weight: bold;
				font-size: 1.3rem;
				margin: 0;
			}
			p {
				font-size: 1.2rem;
				margin: 0;
			}
		}
	}
}

.contact-layer {
	flex: 0 0 100%;
	height: auto;

	@include enable-flex-justify;

	.contact-labels {
		@include contact-labels;
	}
	.contact-form {
		@include contact-form;
	}
}