
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$claim-book-bgcolor: keys.$default-bgcolor;
$claim-book-stress-color: rgba(84,209,252, 1);
$default-box-height: 400px;
$claim-book-icon-width: 45px;

@mixin book-content {
	transition: 0.5s height ease-in-out,
				0.5s flex ease-in-out;
	flex: 0 0 50%;
	height: $default-box-height;
	@include enable-flex-justify;
	background-color: $claim-book-bgcolor;

	article {
		text-align: center;
		.book-title {
			overflow: hidden;
			margin-bottom: 1rem;
			svg {
				margin-top: 7px;
				float: left;
				width: $claim-book-icon-width;
				height: auto;
				color: white;
			}
			h2 {
				transition: 0.5s font-size ease-in-out;
				margin-bottom: 1.8rem;
				margin-left: 0.7rem;
				margin-top: 0;
				@include enable-font-nunito;
				font-size: 1.6rem;
				font-weight: 600;
				color: white;
				float: left;
			}
		}
		.normal-sentence {
			font-size: 1.3rem;
			margin-bottom: 0;
			color: white;
		}
		.stress-sentence {
			margin-top: 0.5rem;
			font-size: 1.4rem;
			color: $claim-book-stress-color;
		}
		.book-button {
			@include main-button;
			a {
				border-radius: 0.5rem;
			}
		}
	}
}

@mixin book-image {
	transition: 0.5s height ease-in-out,
				0.5 flex ease-in-out;
	flex: 0 0 50%;
	height: $default-box-height;
	background-position: center left;
	background-size: cover;
}

.claim-book-layer {
	flex: 0 0 100%;
	@include enable-flex-justify;

	.book-content {
		@include book-content;
	}
	.book-image {
		@include book-image;
	}
}