
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';

$proform-h3-color: keys.$default-bgcolor;
$proform-input-color: keys.$default-bgcolor;
$proform-input-border-color: rgba(84,209,252, 1);
$proform-button-border-color: keys.$default-bgcolor;

@mixin proform-input-error {
	@include contact-form-input-error(red);
	.error {
		display: block;
		font-weight: normal;
		clear: both;
	}
}

@mixin proform-input {
	@include contact-form-input($proform-input-border-color, $proform-input-color);
}

@mixin proform-select {
	div {
		border-color: $proform-input-border-color;
		@include enable-font-nunito;
		font-size: 1rem;
		color: $proform-input-color;
	}
}

@mixin proform-radio-button {
	.form-item-radio {
		display: block;
		float: left;
		margin: 1rem 2rem 1rem 0;
		label {
			&:hover { cursor: pointer; }
			span {
				@include enable-font-nunito-semibold;
				font-size: 1rem;
				&:hover { cursor: pointer; }
			}
		}
	}
}

@mixin proform-button {
	@include main-button;
	flex: 0 0 100%;
	text-align: center;
	a {
		margin-top: 0.5rem;
		color: white;
		border-color: $proform-button-border-color;
		background-color: $proform-button-border-color;
		border-radius: 1rem;
		font-size: 1.2rem;
	}
}

@mixin proform-tab {
	padding: 1rem 2rem 3.5rem 2rem;
	flex: 0 0 100%;
	@include enable-flex;
	justify-content: space-between;

	h3 {
		flex: 0 0 100%;
		@include enable-font-nunito-semibold;
		color: $proform-h3-color;
		font-size: 1.1rem;
	}
	.form-item-name,
	.form-item-email,
	.form-item-phone,
	.form-item-load-type,
	.form-item-origin,
	.form-item-destiny,
	.form-item-incoterm {
		flex: 0 0 30%;
		@include proform-input;
		@include proform-input-error;
	}

	.form-item-load-type,
	.form-item-incoterm {
		@include proform-select;
	}

	.form-item-dimensions {
		flex: 0 0 30%;
		@include enable-flex;
		justify-content: space-between;
		
		.form-item-width,
		.form-item-height,
		.form-item-weight,
		.form-item-size {
			flex: 0 0 47%;
			@include proform-input;
			@include proform-input-error;
		}
	}

	.form-radio-buttons {
		flex: 0 0 100%;
		@include proform-radio-button;
		@include proform-input-error;
	}

	.proform-button {
		@include proform-button;	
	}
}

.body-layer {
	.operations-layer {
		.proform-tab {
			@include proform-tab;
		}
	}
}