
@use 'src/media/styles/variables.scss' as keys;
@import 'src/media/styles/general.scss';


$operation-tabs-bgcolor: keys.$default-bgcolor;

.operations-layer {
	margin-top: -4.4rem;
	flex: 0 0 100%;
	@include enable-flex-justify;
	.operations-navigator {
		flex: 0 0 100%;
		@include enable-flex-justify;
		.navigator-item {
			a {
				line-height: 3rem;
				transition: 0.3s background-color ease-in-out,
							0.3s color ease-in-out;
				@include enable-font-nunito-bold;
				border-radius: 0.7rem;
				font-size: 1.2rem;
				display: block;
				padding: 0.7rem 1.5rem;
				float: left;
				text-decoration: none;
				background-color: $operation-tabs-bgcolor;
				color: white;
				&:hover,
				&.active {
					background-color: white;
					color: $operation-tabs-bgcolor;
				}
				svg {
					float: left;
					width: 45px;
					height: auto;
					margin-right: 0.7rem;
				}
			}
		}
	}
}